import(/* webpackChunkName: "components" */ './components')

// Load react only if we have components on the page.
const components = document.querySelectorAll('[data-component]')

if (components.length) {
    import(/* webpackChunkName: "app" */ './react')
}

// IE11 foreach polyfill
if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = (callback, thisArg) => {
        // eslint-disable-next-line no-param-reassign
        thisArg = thisArg || window
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this)
        }
    }
}
